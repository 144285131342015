<template>
  <v-container fill-height justify-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <v-layout column>
      <v-layout wrap justify-center class="ma-0">
          <v-flex xs12 sm11 md10>
            <v-layout column class="pa-4">
              <h2 class="display-2 raleway grey--text mt-3 mb-4">{{$t('sections.ticket_validation')}}</h2>
              <v-layout wrap class="my-3" align-center>
                <v-flex xs12 sm6>
                  <v-text-field
                    autocomplete="off"
                    :label="$t('fields.ticket_reference')"
                    :readonly="state.loading"
                    v-model="locator"
                    clearable
                    outlined
                    hide-details
                    autofocus
                    v-on:keyup.enter.prevent="locator ? getTicket() : false"
                    @keydown.space.prevent
                    append-icon="mdi-qrcode" />
                </v-flex>
                <v-btn :loading="state.loading" :disabled="!locator" @click="getTicket()" class="ml-4" color="primary">{{$t('actions.validate')}}</v-btn>
              </v-layout>
              <v-alert outlined :value="displayMissingBooking" icon="mdi-alert-outline" type="warning">
                {{$t('ticket_validation.missing_booking')}}
              </v-alert>
            </v-layout>
          </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import searchMixins from '@/mixins/Search'
import { BookingRequests } from '@/services/booking.requests'
import routeNames from '@/router/routes'
const bookingService = new BookingRequests()
export default {
  mixins: [
    searchMixins
  ],
  data () {
    return {
      locator: null,
      displayMissingBooking: false
    }
  },
  computed: {
    ...mapState(['state'])
  },
  methods: {
    getTicket () {
      if (this.locator.includes('http')) {
        var searchParams = new URLSearchParams(this.locator.split(/\?(.+)/)[1])
        this.locator = searchParams.get('locator')
      }
      this.$nextTick(async () => {
        if (!this.locator) {
          this.displayMissingBooking = true
          return
        }
        const bookings = await bookingService.list({ locator: this.locator.trim(), limit: 1, offset: 0 })
        if (!bookings.list.length) {
          this.displayMissingBooking = true
        } else {
          this.$router.push({ name: routeNames.ticket.name, params: { id: bookings.list[0].booking.id } })
        }
      })
    }
  },
  watch: {
    locator () {
      this.displayMissingBooking = false
    }
  }
}
</script>
